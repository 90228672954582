.billing-info-body {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.otis-select-drop-down {
  min-width: 170px;
  width: 100%;
}

.credit-card-control {
  display: flex;
  flex-direction: column;
  width: 100%;

  // Add styles for Recurly hosted fields
  [data-recurly='number'],
  [data-recurly='month'],
  [data-recurly='year'],
  [data-recurly='cvv'] {
    font-size: 16px !important;
    height: 36px !important;
    padding: 8px 0 !important;
  }

  // Style for the form labels
  .form-label {
    font-size: 14px !important;
  }
}

//mobile view
@media screen and (max-width: 720px) {
  .credit-card-control {
    margin-top: 25px;
    padding-bottom: 150px;

    // Increase size specifically for mobile
    [data-recurly='number'],
    [data-recurly='month'],
    [data-recurly='year'],
    [data-recurly='cvv'] {
      font-size: 16px !important;
      height: 40px !important;
      padding: 10px 0 !important;
    }
  }

  .billing-info-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    margin-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
