.login_container {
  max-width: 500px !important;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  padding: 0 20px;
  display: list-item;
}

.progress-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-wrapper-center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recurly-input {
  width: 100%;
}

.recurly-hosted-field {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-image: none;
  background-clip: padding-box;
  height: 37px;
}

.recurly-input.is-invalid ~ .recurly-hosted-field {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-image: none;
  background-clip: padding-box;
  border-bottom: 1px solid red;
  height: 37px;
}

.recurly-hosted-field-focus {
  border-color: #b3d4fc;
  color: #495057;
  z-index: 10;
}

.recurly-input.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-control {
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 0 0 1px 0;
  width: 100%;
}

.separator {
  display: block;
  width: 5px;
  height: 100%;
  margin: 10px;
}

.text-bold {
  font-weight: bold;
}

.MuiTab-wrapper {
  font-weight: bold !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.body-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
}

.main-layout {
  position: absolute;
  top: 71px;
  right: 0;
  bottom: 60px;
  left: 0;
  overflow: auto;
}

.carousel-icon {
  margin: -15px !important;
}

.dash-carousel {
  padding: 0 !important;
}

.card-divider {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

// Mobile view
@media screen and (max-width: 600px) {
  .card-divider {
    margin-top: 6px !important;
    margin-bottom: 8px !important;
  }

  .main-layout {
    top: 0;
  }
}

#address-element {
  margin-bottom: 24px;
}
